import React, { useEffect, useRef, useState } from "react";
import "./viewerWrapper.scss";
import { useParams } from "react-router-dom";
import { Button, Drawer } from "antd";
import { FormOutlined } from "@ant-design/icons";
import ReportForm from "../ReportForm";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";

interface ViewerWrapperProps extends AuthReducerProps {}

function ViewerWrapper({ userRole }: ViewerWrapperProps) {
  const { caseId }: any = useParams();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [caseDetail, setCaseDetail] = useState<CaseDetail>();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const url: string | undefined = process.env.REACT_APP_VIEWER_BASE_URL ?? "";

  const handleDrawerOpen = () => setDrawerVisible(true);

  const handleDrawerClose = () => setDrawerVisible(false);

  useEffect(() => {
    if (caseId && userRole) {
      const showCase =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCase
          : DoctorCaseService.showDoctorCase;
      showCase(
        Number(caseId),
        (caseDetail: CaseDetail) => {
          setCaseDetail(caseDetail);
        },
        () => {},
        () => {}
      );
    }
  }, [caseId, userRole]);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeWindow = iframeRef.current.contentWindow;
      const auth = JSON.parse(localStorage.getItem("auth") ?? "{}");
      iframeRef.current.onload = () => {
        iframeWindow?.postMessage({ auth }, url);
      };
    }
  }, []);

  return (
    <div className={`viewer-wrapper ${drawerVisible ? 'drawer-open' : ''}`}>
      <div className="viewer-wrapper__generate-wrapper">
        {caseDetail?.status === CaseStatusEnum.ASSIGNED && (
          <Button type="primary" onClick={handleDrawerOpen}>
            <FormOutlined />
            Generate Report
          </Button>
        )}
      </div>
      <div className="viewer-wrapper__iframe-wrapper">
        <iframe
          ref={iframeRef}
          src={`${url}?caseId=${caseId}&drawerOpen=${drawerVisible}`}
          title="Case viewer"
          className="viewer-wrapper__iframe"
        />
      </div>
      <Drawer
        className="viewer-wrapper__generate-report-drawer"
        title={<h2>Generate Report</h2>}
        width="100%"
        onClose={handleDrawerClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        footer={null}
      >
        {caseDetail && <ReportForm fromViewer caseDetailId={caseDetail.id} />}
      </Drawer>
    </div>
  );
}

export default AuthContainer(ViewerWrapper);
