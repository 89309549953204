import React, { useCallback, useEffect, useState } from "react";
import "./appSidebar.scss";
import { Menu } from "antd";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import ActionCable, { Cable } from "actioncable";
import { getHeaders } from "../../../interceptor/axiosInstance";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import ProfileService from "../../../services/Profile/profile.service";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import { CaseUploadStatusEnum } from "../../../enums/caseUploadStatus.enum";
import { useHistory } from 'react-router-dom';
import { LeftOutlined, MenuUnfoldOutlined, DatabaseFilled, UserOutlined, DiffTwoTone, MedicineBoxOutlined, TeamOutlined, SettingOutlined, ProfileOutlined } from '@ant-design/icons'; // Import Ant Design icons

interface AppSidebarProps extends AuthReducerProps, CaseReducerProps {
    collapsed: boolean
    setCollapsed: (collapsed: boolean) => void;
}

function AppSidebar({
  authenticated,
  userRole,
  user,
  setUploadStart,
  setUploadInProgress,
  setUploadComplete,
  collapsed,
  setCollapsed,
}: AppSidebarProps) {
  const adminId = userRole === UserRoleEnum.ADMIN ? user?.id : user?.adminId;
  const history = useHistory();
  const [pathname, setPathname] = useState<string>(history.location.pathname);
  const [scanCenter, setScanCenter] = useState<ScanCenter>();
//   const [collapsed, setCollapsed] = useState(false); // State for sidebar collapse
//   const [collapsed, setCollapsed] = useState(window.innerWidth < 768); 
  const handleClick = (e: any) => {
    history.push(e.key);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed); // Toggle sidebar collapse state
  };

  const handleResize = () => {
    if (window.innerWidth < 1300) {
      setCollapsed(true); // Collapse sidebar on small screens
    } else {
      setCollapsed(false); // Expand sidebar on larger screens
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // Cleanup on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  useEffect(() => {
    if (authenticated && userRole === UserRoleEnum.SCAN_CENTER) {
      ProfileService.getProfile(
        userRole,
        (scanCenter: ScanCenter) => {
          setScanCenter(scanCenter);
        },
        () => {},
        () => {}
      );
    }
  }, [authenticated, userRole]);

  return authenticated ? (
    <div className={`app-sidebar ${collapsed ? 'collapsed' : ''}`}>
      {/* Sidebar header with toggle button */}
      <div className="app-sidebar__header">
      {collapsed ? (
            <MenuUnfoldOutlined onClick={toggleCollapsed} className="app-sidebar__toggle collapsed" />
            ) : (
            <LeftOutlined onClick={toggleCollapsed} className="app-sidebar__toggle expanded" />
        )}
        {!collapsed && (
          <>
            {adminId === 2 ? (
              <>
                <h1 className="app-sidebar__logo">CONRAD</h1>
                <h5 className="app-sidebar__logo-subtext">TELERADIOLOGY</h5>
              </>
            ) : (
              <h1 className="app-sidebar__logo">RadiolinQ</h1>
            )}
          </>
        )}
         {!collapsed && (<div className="app-sidebar__role">{userRole?.split("_").join(" ")}</div>)}
      </div>

      {/* Sidebar Menu */}
      <Menu
        selectedKeys={[pathname]}
        onClick={handleClick}
        defaultSelectedKeys={[AppRoutes.HOME]}
        mode="inline"
        inlineCollapsed={collapsed} // Use collapsed state for the menu
      >
        <Menu.Item key={AppRoutes.HOME} icon={<DatabaseFilled/>}>
          <div className="app-sidebar__menu-title">Cases</div>
        </Menu.Item>
        {userRole === UserRoleEnum.ADMIN && (
          <>
            <Menu.Item key={AppRoutes.SCAN_CENTRES} icon={<MedicineBoxOutlined/>}>
              <div className="app-sidebar__menu-title">Scan Centers</div>
            </Menu.Item>
            <Menu.Item key={AppRoutes.DOCTORS} icon={<TeamOutlined/>}>
              <div className="app-sidebar__menu-title">Doctors</div>
            </Menu.Item>
          </>
        )}
        {(userRole === UserRoleEnum.DOCTOR || userRole === UserRoleEnum.ADMIN) && (
          <Menu.Item key={AppRoutes.REPORT_TEMPLATES} icon={<DiffTwoTone/>}>
            <div className="app-sidebar__menu-title">Report Templates</div>
          </Menu.Item>
        )}
        <Menu.Item key={AppRoutes.PROFILE} icon={<SettingOutlined/>}>
          <div className="app-sidebar__menu-title">Profile</div>
        </Menu.Item>
      </Menu>

      {adminId === 2 && (
        <div className="app-sidebar__footer">
          {!collapsed && (
            <h1>
              <span>Powered by</span> <br />
              RadiolinQ
            </h1>
          )}
        </div>
      )}
    </div>
  ) : null;
}

export default CaseContainer(AuthContainer(AppSidebar));
